import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [0,2,3,4,5,6];

export const dictionary = {
		"/": [~7],
		"/(user)/assistant": [8,[2]],
		"/(user)/assistant/[campaignId]": [~9,[2,3]],
		"/(user)/assistant/[campaignId]/chat": [~10,[2,3]],
		"/(user)/campaigns": [11,[2]],
		"/(user)/campaigns/new": [~13,[2]],
		"/(user)/campaigns/[campaignId]": [~12,[2]],
		"/(user)/creations": [14,[2]],
		"/(user)/creations/[campaignId]": [~15,[2,4]],
		"/(user)/creations/[campaignId]/create": [~18,[2,4]],
		"/(user)/creations/[campaignId]/create/[creationType]": [~19,[2,4]],
		"/(user)/creations/[campaignId]/link": [~20,[2,4]],
		"/(user)/creations/[campaignId]/[creationId]": [~16,[2,4,5]],
		"/(user)/creations/[campaignId]/[creationId]/edit": [~17,[2,4,5]],
		"/forgot-password": [~28],
		"/(user)/intro": [~21,[2]],
		"/(user)/invitations": [~22,[2]],
		"/reset-password": [~29],
		"/(user)/sessions": [23,[2]],
		"/(user)/sessions/[campaignId]": [~24,[2,6]],
		"/(user)/sessions/[campaignId]/record": [~27,[2,6]],
		"/(user)/sessions/[campaignId]/[sessionId]/summary": [~25,[2,6]],
		"/(user)/sessions/[campaignId]/[sessionId]/transcript": [~26,[2,6]],
		"/signup": [~30],
		"/verify-email": [~31]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.js';